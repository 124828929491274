import * as React from "react";

const SvgComponent = ({ width = 14, height = 14, fill = "var(--white)" }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.28 1h.03l.04.003L12.28 1a.754.754 0 0 1 .448.149l.01.007a.754.754 0 0 1 .148.153l-.074-.087a.754.754 0 0 1 .21.426l.007.102v7.944a.75.75 0 0 1-1.493.102l-.007-.102V3.55l-9.25 9.177a.75.75 0 0 1-1.13-.981l.073-.084 9.236-9.163L4.25 2.5a.75.75 0 0 1-.743-.648L3.5 1.75a.75.75 0 0 1 .648-.743L4.25 1h8.03Z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
