import * as React from "react";

const SvgComponent = (props) => (
  <svg width={26} height={28} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.758 26.232a.506.506 0 0 1 .18.98l-.092.03-.088.008H9.335a.506.506 0 0 1-.18-.98l.092-.03.088-.008h7.423Zm-7.395.499-.03.001a.01.01 0 0 0-.007.01l.002.005.007.003 7.395.002.03-.002a.009.009 0 0 0 .007-.009l-.002-.006-.007-.003-7.395-.001ZM12.14 6.252a7.038 7.038 0 0 1 4.517 13.017l-.272.155-.132.067v2.07c0 .24-.068.473-.194.673l-.082.116-.095.106c-.165.165-.37.28-.604.336l-.143.026-.15.01h-3.883a1.268 1.268 0 0 1-1.234-1l-.022-.126-.008-.133v-2.077l-.138-.07a7.031 7.031 0 0 1-2.33-2.027l-.18-.256-.167-.264A7.04 7.04 0 0 1 12.14 6.252Zm4.465 1.496a6.538 6.538 0 1 0-6.667 11.233l.259.133.141.068v2.37l.006.104a.767.767 0 0 0 .655.664l.103.008h3.867l.117-.007a.768.768 0 0 0 .658-.64l.01-.12v-2.375l.399-.204a6.536 6.536 0 0 0 .692-11.07l-.24-.164Zm-3.558-1.034a6.517 6.517 0 0 1 6.519 6.52 6.533 6.533 0 0 1-3.563 5.796l-.264.128-.001.005-.002 2.431-.012.11a.75.75 0 0 1-.627.598l-.106.008-3.905-.001-.112-.011a.75.75 0 0 1-.608-.63l-.008-.108.002-2.355-.002-.03-.006-.007a6.517 6.517 0 0 1-2.596-9.74 6.52 6.52 0 0 1 5.29-2.714Zm0 .5a6.02 6.02 0 0 0-2.49 11.499.499.499 0 0 1 .26.272l.032.103.009.088v2.384c0 .097.056.182.127.222l.056.02.067.008h3.882a.258.258 0 0 0 .219-.125l.02-.054.009-.063v-2.405c0-.2.117-.38.298-.463a6.038 6.038 0 0 0 3.53-5.467 6.019 6.019 0 0 0-6.02-6.02Zm-8.622 5.324a.509.509 0 0 1 .181.981l-.081.025-.1.011H1.259a.506.506 0 0 1-.18-.98l.092-.029.088-.008h3.166Zm20.41 0a.506.506 0 0 1 .18.98l-.093.029-.088.008H21.67a.506.506 0 0 1-.18-.98l.092-.029.088-.008h3.165Zm-3.81-8.188a.51.51 0 0 1 .646-.058l.07.058.055.066a.51.51 0 0 1 .051.487l-.04.086-.065.08-2.227 2.225a.513.513 0 0 1-.745.026.513.513 0 0 1-.15-.379.494.494 0 0 1 .071-.234l.053-.077.041-.042 2.24-2.238Zm-16.658.015a.51.51 0 0 1 .498-.13l.085.032.028.016.095.068 2.252 2.251a.504.504 0 0 1-.541.81l-.096-.037-.095-.069-2.252-2.25a.507.507 0 0 1 .026-.69ZM13.047.75c.207 0 .39.125.471.329l.029.092.008.088v3.166a.506.506 0 0 1-.98.18l-.029-.092-.008-.088V1.259c0-.282.228-.509.509-.509Z"
      fill="#232323"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
