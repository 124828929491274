import { useState, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "./styles.module.css";
import CommunityIcon from "./sustainableCommunity.jsx";
import FinanceIcon from "./sustainableFinance.jsx";
import InnovationIcon from "./sustainableInnovation.jsx";
import Logo from "../Logo";
import Button from "../Button";
import { Chevron } from "../Icons";
import cn from "classnames";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import MyImage from "../MyImage";

const NavItem = ({ title, label, href, dropdownItems, dropdownSmallItems }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <li
      className={cn(styles.navItem, {
        [styles.navItemOpen]: isOpen,
        [styles.navItemHasDropdown]: !!dropdownItems || !!dropdownSmallItems,
      })}
    >
      {!!(!!dropdownItems || !!dropdownSmallItems) && (
        <div className={styles.chevron}>
          <Chevron fill="var(--black)" />
        </div>
      )}

      {href ? (
        <a
          className={styles.navItemTitle}
          href={href}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </a>
      ) : (
        <span className={styles.navItemTitle} onClick={() => setOpen(!isOpen)}>
          {title}
        </span>
      )}
      {dropdownItems && (
        <div className={styles.dropdown}>
          <div className={styles.label}>{label}</div>
          <ul className={styles.dropdownItems}>
            {dropdownItems.map(({ title, href, icon }) => (
              <li
                key={title}
                className={cn(styles.dropdownItem, {
                  [styles.dropdownItemWithIcon]: !!icon,
                })}
              >
                <a href={href}>
                  {icon && <div className={styles.icon}>{icon()}</div>}
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {dropdownSmallItems && (
        <div className={styles.dropdownSmall}>
          <ul className={styles.dropdownItems}>
            {dropdownSmallItems.map(({ title, href, icon }) => (
              <li className={styles.dropdownItem} key={title}>
                <a href={href}>
                  {icon && (
                    <div className={styles.icon}>
                      <MyImage
                        src={icon}
                        width="36"
                        height="24"
                        layout="fixed"
                        objectFit="contain"
                        alt="icon"
                      />
                    </div>
                  )}
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default function Header({ isDark = false }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);
  const onMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      enablePageScroll(navRef.current);
    } else {
      disablePageScroll(navRef.current);
    }
  };
  return (
    <header className={cn(styles.header, { [styles.headerDark]: isDark })}>
      <Link href="/" passHref>
        <a className={styles.logo} aria-label="homepage">
          <Logo isDark={isDark ? true : isMenuOpen} />
        </a>
      </Link>
      <nav
        className={cn(styles.nav, { [styles.navOpen]: isMenuOpen })}
        ref={navRef}
      >
        <ul className={styles.navItems}>
          <NavItem
            title="Our Work"
            label="Practice areas"
            dropdownItems={[
              {
                title: "Sustainable Finance",
                href: "/pages/sustainable-finance",
                icon: FinanceIcon,
              },
              {
                title: "Sustainable Communities",
                href: "/pages/sustainable-communities",
                icon: CommunityIcon,
              },
              {
                title: "Sustainable Innovation",
                href: "/pages/sustainable-innovation",
                icon: InnovationIcon,
              },
              { title: "Overview", href: "/pages/overview" },
              { title: "Experience", href: "/pages/experience" },
            ]}
          />
          <NavItem
            title="Our Company"
            dropdownSmallItems={[
              { title: "About Us", href: "/pages/about-us" },
              { title: "Meet the team", href: "/pages/team" },
              { title: "Opportunities", href: "/pages/opportunities" },
            ]}
          />
          <NavItem title="Resources" href="/resources" />
        </ul>
        <div className={styles.buttonWrapper}>
          <Button href="/pages/contact-us" type="darkYellowOnWhite" withArrow>
            Contact Us
          </Button>
        </div>
        <div className={styles.buttonWrapperMobile}>
          <Button href="/pages/contact-us" type="yellowOnWhite" withArrow>
            Contact Us
          </Button>
        </div>
      </nav>
      <button
        aria-label="menu toggle"
        className={cn(styles.menuToggle, {
          [styles.menuToggleActive]: isMenuOpen,
        })}
        onClick={onMenuToggle}
      >
        <span></span>
      </button>
    </header>
  );
}
