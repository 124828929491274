import * as React from "react";

const SvgComponent = (props) => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.833 1.1a4.733 4.733 0 0 1 3.775 7.59l3.102 3.1a.65.65 0 0 1-.84.988l-.08-.068-3.1-3.102A4.733 4.733 0 1 1 5.833 1.1Zm0 1.3a3.433 3.433 0 1 0 0 6.867 3.433 3.433 0 0 0 0-6.867Z"
      fill="#b5b5b5"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
