import * as React from "react";

const SvgComponent = ({ fill = "var(--white)", ...props }) => (
  <svg
    width={10}
    height={6}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 10 6"
    {...props}
  >
    <defs>
      <path
        d="M10 .85c0 .079-.026.159-.103.238L5.232 5.894A.312.312 0 0 1 5 6a.309.309 0 0 1-.232-.106L.103 1.088a.327.327 0 0 1 0-.478l.49-.504A.312.312 0 0 1 .825 0c.077 0 .155.026.232.106L5 4.17 8.943.106A.312.312 0 0 1 9.175 0c.077 0 .155.026.232.106l.49.504c.051.08.103.16.103.24Z"
        id="a"
      />
    </defs>
    <use fill={fill} xlinkHref="#a" fillRule="evenodd" />
  </svg>
);

export default SvgComponent;
