import styles from "./styles.module.css";
import cn from "classnames";

export default function Container({ children, isWide, className }) {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.containerWide]: isWide,
      })}
    >
      {children}
    </div>
  );
}
