import * as React from "react";

const SvgComponent = ({ fill = "var(--black)" }, ...props) => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.21 1.21a.72.72 0 0 1 .938-.069l.081.07 5.28 5.28a.72.72 0 0 1 .07.937l-.07.081-5.28 5.28a.72.72 0 0 1-1.088-.937l.07-.081L8.98 7l-4.77-4.772a.72.72 0 0 1-.07-.937l.07-.081Z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
