import Link from "next/link";
import Image from "next/image";
import styles from "./styles.module.css";
import { Arrow } from "../Icons";
import cn from "classnames";

const Button = ({
  href,
  onClick = () => {},
  children,
  withArrow = false,
  type,
}) => {
  const classes = cn({
    [styles.button]: true,
    [styles.white]: type === "white",
    [styles.whiteOnTurquoise]: type === "whiteOnTurquoise",
    [styles.green]: type === "green",
    [styles.greenOnWhite]: type === "greenOnWhite",
    [styles.yellow]: type === "yellow",
    [styles.yellowOnWhite]: type === "yellowOnWhite",
    [styles.darkYellowOnWhite]: type === "darkYellowOnWhite",
    [styles.yellowSVG]: type === "yellowSVG",
  });

  if (!href) {
    return (
      <button className={classes} onClick={onClick}>
        {children}{" "}
        {withArrow && (
          <div className={styles.icon}>
            <Arrow />
          </div>
        )}
      </button>
    );
  }
  return (
    <Link passHref href={href || "#"}>
      <a className={classes}>
        {children}{" "}
        {withArrow && (
          <div className={styles.icon}>
            <Arrow />
          </div>
        )}
      </a>
    </Link>
  );
};

export default Button;
