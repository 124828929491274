import * as React from "react"

const SvgComponent = (props) => (
  <svg width={36} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C5.575 0 3.6 1.94 3.6 4.321c0 1.191.49 2.275 1.288 3.057C2.03 8.497 0 11.05 0 14.143c0 .216.18.393.4.393h11.125C9.142 16.19 7.6 18.739 7.6 21.607c0 .217.18.393.4.393h20c.22 0 .4-.176.4-.393 0-2.868-1.542-5.417-3.925-7.071H35.6c.22 0 .4-.177.4-.393 0-3.094-2.031-5.646-4.888-6.765A4.274 4.274 0 0 0 32.4 4.321C32.4 1.94 30.425 0 28 0c-2.425 0-4.4 1.94-4.4 4.321 0 1.191.49 2.275 1.287 3.057a8.33 8.33 0 0 0-1.75.921C22.77 5.833 20.611 3.93 18 3.93c-2.611 0-4.77 1.904-5.138 4.37a8.33 8.33 0 0 0-1.75-.92 4.274 4.274 0 0 0 1.287-3.058C12.4 1.94 10.424 0 8 0H8Zm0 .786c1.992 0 3.6 1.579 3.6 3.535 0 1.957-1.608 3.536-3.6 3.536S4.4 6.278 4.4 4.321C4.4 2.365 6.008.786 8 .786Zm20 0c1.993 0 3.6 1.579 3.6 3.535 0 1.957-1.607 3.536-3.6 3.536-1.992 0-3.6-1.579-3.6-3.536 0-1.956 1.608-3.535 3.6-3.535ZM18 4.714c2.435 0 4.4 1.93 4.4 4.322 0 2.39-1.965 4.321-4.4 4.321-2.434 0-4.4-1.93-4.4-4.321s1.966-4.322 4.4-4.322ZM5.613 7.955c.687.438 1.509.688 2.387.688.878 0 1.7-.25 2.388-.688a7.586 7.586 0 0 1 2.425 1.302 5.08 5.08 0 0 0 1.937 3.756c-.662.19-1.289.44-1.887.737a.424.424 0 0 0-.063 0H.862c.177-2.718 2.07-4.92 4.75-5.795Zm20 0c.687.438 1.51.688 2.387.688.878 0 1.7-.25 2.388-.688 2.681.875 4.572 3.077 4.75 5.795H23.2a.356.356 0 0 0-.037.012 11.06 11.06 0 0 0-1.913-.749 5.08 5.08 0 0 0 1.938-3.756 7.587 7.587 0 0 1 2.425-1.301Zm-9.938 5.648c.7.345 1.49.54 2.325.54.836 0 1.625-.195 2.325-.54 4.053.868 7.033 3.937 7.238 7.611H8.438c.204-3.674 3.184-6.743 7.237-7.611Z"
      fill="#232323"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgComponent
