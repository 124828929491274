import Image from "next/image";
import LogoImage from "./logo.png";
import LogoImageDark from "./logoDark.png";
import styles from "./styles.module.css";
import MyImage from "../MyImage";

export default function Logo({ isDark, isSmall }) {
  return (
    <div className={styles.logo}>
      {isDark ? (
        <MyImage src={LogoImageDark} layout="fill" alt="logo" />
      ) : (
        <MyImage src={LogoImage} layout="fill" alt="logo" />
      )}
    </div>
  );
}
