import Alert from "./Alert";
import Meta from "./Meta";
import Script from "next/script";

export default function Layout({ preview, children, slug, seo }) {
  return (
    <>
      <Meta seo={seo} />
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-YZXFQBKZG1" />
      {process.env.VERCEL_ENV === "production" && (
        <>
          <Script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-YZXFQBKZG1');
              `,
            }}
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `
              (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
              })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
              ga('create', 'UA-66742133-1', 'auto');
              ga('send', 'pageview');
              `,
            }}
          />
        </>
      )}
      <div className={`min-h-screen ${slug ? slug : ""}`}>
        <Alert preview={preview} />
        <main>{children}</main>
      </div>
    </>
  );
}
