import Head from "next/head";
import { CMS_NAME, HOME_OG_IMAGE_URL } from "../../lib/constants";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

export default function Meta({seo}) {
  const { asPath } = useRouter();
  return (
    <>
    {seo && (
      <NextSeo
        title={seo.title ? seo.title : "Sustanaible Capital Advisors"}
        description={seo.metaDesc ? seo.metaDesc : "Sustanaible Capital Advisors"}
        openGraph={{
          type: seo.opengraphType ? seo.opengraphType : "article",
          url: `https://scap.com${asPath}`,
          title: seo.opengraphTitle ? seo.opengraphTitle : seo.opengraphTitle,
          description: seo.opengraphDescription
            ? seo.opengraphDescription
            : seo.description,
          site_name: seo.opengraphPublisher,
          images: seo?.opengraphImage?.sourceUrl
            ? [
                {
                  url: seo?.opengraphImage?.sourceUrl,
                },
              ]
            : [],
        }}
      />
    )}
    <Head>
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon.png"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
    </Head>
    </>
  );
}
