import * as React from "react";

const ArrowIcon = ({ fill, ...props }) => {
  const id = `id${Math.random() * 1000000}`;
  return (
    <svg
      width={18}
      height={12}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M18 6a.768.768 0 0 0-.167-.415l-5-5.368c-.218-.28-.668-.28-.944-.05a.596.596 0 0 0-.056.879l4.032 4.322H.667c-.369 0-.667.282-.667.631 0 .35.298.632.667.632h15.198l-4.032 4.322c-.264.242-.19.658.088.887.277.23.695.214.912-.058l5-5.368A.6.6 0 0 0 18 6Z"
          id={id}
        />
      </defs>
      <use fill={fill} xlinkHref={`#${id}`} fillRule="evenodd" />
    </svg>
  );
};

export default ArrowIcon;
