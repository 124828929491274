import Image from "next/image";

const MyImage = ({ src, ...props }) => {
  // Check if the src starts with "/wp-content"
  if (String(src)?.startsWith("/wp-content")) {
    // Add the site URL before the src
    src = `https://scap-wp.azurewebsites.net${src}`;
  }

  return <Image src={src} {...props} />;
};

export default MyImage;
