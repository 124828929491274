import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={64}
    height={64}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="99.984%" x2="100%" y2="0%" id="cursoropenc">
        <stop stopColor="#2164C5" offset="0%" />
        <stop stopColor="#2874C6" offset="47.925%" />
        <stop stopColor="#0EB8C9" offset="100%" />
      </linearGradient>
      <path
        d="M32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0Zm0 .96C14.857.96.96 14.857.96 32S14.857 63.04 32 63.04 63.04 49.143 63.04 32 49.143.96 32 .96Zm.6 18.16V31.5h11.9v1H32.6v12.22h-1V32.5H18.9v-1h12.7V19.12h1Z"
        id="cursoropena"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fillOpacity={0.701} fill="#FFF" cx={32} cy={32} r={32} />
      <mask id="cursoropenb" fill="#fff">
        <use xlinkHref="#cursoropena" />
      </mask>
      <use fill="#979797" fillRule="nonzero" xlinkHref="#cursoropena" />
      <g mask="url(#cursoropenb)" fill="url(#cursoropenc)">
        <path d="M0 0h64v64H0z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
