import * as React from "react"

const SvgComponent = (props) => (
  <svg width={28} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.97 0a.35.35 0 0 0-.102.031L.193 5.87A.325.325 0 0 0 0 6.163v1.946c0 .178.146.324.326.324h4.558v9.73H2.574a.324.324 0 0 0-.295.324v1.946H.326a.326.326 0 0 0-.326.324v2.919c0 .178.146.324.326.324h27.348c.18 0 .326-.146.326-.324v-2.92a.326.326 0 0 0-.326-.323h-1.953v-1.946a.326.326 0 0 0-.326-.325h-2.279v-9.73h4.558c.18 0 .326-.145.326-.323V6.163a.325.325 0 0 0-.193-.294L14.132.03a.328.328 0 0 0-.163-.03Zm.03.68 13.349 5.695v1.41H.65v-1.41L14 .68ZM5.535 8.433h1.953v9.73H5.535v-9.73Zm2.605 0h4.232v9.73H8.14v-9.73Zm4.883 0h1.954v9.73h-1.954v-9.73Zm2.605 0h4.232v9.73h-4.232v-9.73Zm4.884 0h1.953v9.73h-1.953v-9.73ZM2.93 18.81h22.14v1.946c0 .178.146.324.325.324h1.954v2.27H.65v-2.27h1.954c.179 0 .325-.146.325-.324V18.81Z"
      fill="#232323"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgComponent
