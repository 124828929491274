import * as React from "react";

const Location = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.346 21.992a.926.926 0 0 1-.116-.027l-.006-.002-.017-.006a.706.706 0 0 1-.073-.031l-.016-.008-5.53-2.915-5.529 2.915c-.456.24-.988-.073-1.052-.586L2 21.227V7.851c0-.256.12-.492.316-.635l.089-.055L8.26 4.074l.01-.006a.707.707 0 0 1 .07-.03l-.08.036a.71.71 0 0 1 .534-.05l.015.005.03.01a.707.707 0 0 1 .062.028l.014.007 1.464.772c.362.19.508.654.328 1.035a.718.718 0 0 1-.89.386l-.093-.04-.405-.215v11.651l4.393 2.315v-3.895c0-.427.328-.772.732-.772.371 0 .677.29.726.667l.006.105v3.895l4.393-2.315v-1.58c0-.391.275-.714.633-.765l.099-.007c.37 0 .677.29.725.667l.007.105v2.057c0 .256-.12.493-.316.636l-.089.055-5.856 3.087-.013.006a.706.706 0 0 1-.08.034l.093-.04a.71.71 0 0 1-.228.074l-.1.007-.099-.007Zm-6.49-15.98L3.464 8.327v11.651l4.392-2.315V6.012Zm12.476-2.888c1.66 1.45 2.145 3.903 1.165 5.985l-.107.214-3.441 5.18-.044.053-.027.029-.051.046-.034.026-.052.036-.04.022-.053.025-.045.018-.101.027-.103.011h-.05l-.052-.004-.051-.007-.05-.012-.051-.015-.046-.018-.052-.025-.04-.022-.053-.036-.033-.026-.051-.046-.028-.03-.044-.053-3.481-5.248c-1.08-2.056-.618-4.63 1.098-6.13a4.455 4.455 0 0 1 5.917 0ZM15.35 4.313c-1.123.98-1.46 2.635-.877 3.948l.085.176 2.817 4.242 2.775-4.173c.708-1.348.448-3.021-.609-4.061l-.142-.132a3.048 3.048 0 0 0-4.049 0Zm2.025 1.737c.37 0 .677.29.725.667l.007.115c0 .427-.328.772-.732.772-.37 0-.677-.29-.726-.667l-.006-.115c0-.426.327-.772.732-.772Z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Location;
