import * as React from "react";

const SvgComponent = ({ fill = "var(--white)", width = 14, height = 14 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.484 12.745c.285 0 .516.269.516.6 0 .299-.187.546-.433.592l-.083.008H.516c-.285 0-.516-.268-.516-.6 0-.298.187-.545.433-.592l.083-.008h12.968ZM7 0c.311 0 .57.237.618.547l.009.105-.001 7.632 1.977-2.057a.608.608 0 0 1 .8-.075l.086.075a.673.673 0 0 1 .072.832l-.072.09-3.046 3.172a.608.608 0 0 1-.8.075l-.086-.075L3.51 7.15a.671.671 0 0 1 0-.923.608.608 0 0 1 .8-.075l.086.075 1.976 2.056V.653C6.373.292 6.653 0 7 0Z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
