import * as React from "react";

const SvgComponent = (props) => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 1.15a5.85 5.85 0 1 1 0 11.7 5.85 5.85 0 0 1 0-11.7Zm0 1.2a4.65 4.65 0 1 0 0 9.3 4.65 4.65 0 0 0 0-9.3ZM7 6.4a.6.6 0 0 1 .592.503L7.6 7v1.734l.08.007a.6.6 0 0 1 .495.495l.008.097a.6.6 0 0 1-.502.592l-.098.008H7a.6.6 0 0 1-.592-.502L6.4 9.333V7.6l-.08-.007a.6.6 0 0 1-.495-.495L5.817 7a.6.6 0 0 1 .502-.592l.098-.008H7Zm.006-2.333a.6.6 0 0 1 .097 1.192l-.097.008a.601.601 0 0 1-.103-1.192l.103-.008Z"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
