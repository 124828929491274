import * as React from "react";

const Print = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"export/print"}</title>
    <path
      d="M15.077 2a2.82 2.82 0 0 1 2.815 2.649l.005.172v3.332h1.282a2.82 2.82 0 0 1 2.816 2.65l.005.171v4.103a2.82 2.82 0 0 1-2.649 2.815l-.172.005h-1.282v1.282a2.82 2.82 0 0 1-2.648 2.816l-.172.005H8.923a2.82 2.82 0 0 1-2.82-2.82l-.001-1.283H4.821a2.82 2.82 0 0 1-2.816-2.648L2 15.077v-4.103A2.82 2.82 0 0 1 4.649 8.16l.172-.005 1.281-.001V4.821a2.82 2.82 0 0 1 2.65-2.816L8.922 2h6.154Zm0 11.795H8.923c-.708 0-1.282.574-1.282 1.282v4.102c0 .709.574 1.283 1.282 1.283h6.154c.708 0 1.282-.574 1.282-1.283v-4.102c0-.708-.574-1.282-1.282-1.282Zm4.102-4.103H4.821c-.664 0-1.21.505-1.276 1.151l-.007.131v4.103c0 .664.505 1.21 1.151 1.275l.132.007 1.281-.001v-1.281a2.82 2.82 0 0 1 2.65-2.815l.171-.006h6.154a2.82 2.82 0 0 1 2.82 2.82v1.282l1.282.001c.664 0 1.21-.505 1.276-1.151l.007-.131v-4.103c0-.663-.505-1.21-1.151-1.275l-.132-.007Zm-4.102-6.154H8.923c-.664 0-1.21.505-1.275 1.151l-.007.132v3.332h8.717l.001-3.332c0-.664-.505-1.21-1.151-1.276l-.131-.007Z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Print;
