import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M20 4.012a8.256 8.256 0 0 1-2.363.677 4.237 4.237 0 0 0 1.804-2.37 7.978 7.978 0 0 1-2.6 1.038 4.027 4.027 0 0 0-2.995-1.358C11.576 2 9.75 3.927 9.75 6.29c0 .34.027.668.095.979-3.409-.174-6.425-1.883-8.451-4.487A4.472 4.472 0 0 0 .83 4.95c0 1.486.732 2.803 1.821 3.565A3.913 3.913 0 0 1 .8 7.986v.047c0 2.084 1.421 3.816 3.285 4.215a3.922 3.922 0 0 1-1.075.14 3.47 3.47 0 0 1-.776-.072c.531 1.698 2.038 2.947 3.831 2.988A8.01 8.01 0 0 1 0 17.074 11.176 11.176 0 0 0 6.29 19c7.545 0 11.67-6.538 11.67-12.206 0-.19-.006-.373-.015-.554A8.398 8.398 0 0 0 20 4.012Z"
        id="twitter"
      />
    </defs>
    <use fill="#B5BBCB" fillRule="nonzero" xlinkHref="#twitter" />
  </svg>
)

export default SvgComponent
