import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M16.25 0A3.761 3.761 0 0 1 20 3.75v12.5A3.761 3.761 0 0 1 16.25 20H3.75A3.761 3.761 0 0 1 0 16.25V3.75A3.761 3.761 0 0 1 3.75 0h12.5Zm-2.406 7c-1.563 0-2.625.844-3.063 1.688h-.031V7.28H7.656V17.72h3.25V12.53c0-1.375.281-2.687 1.938-2.687 1.625 0 1.656 1.594 1.656 2.781v5.063h3.219v-5.72c0-2.812-.594-4.968-3.875-4.968Zm-8.281.281h-3.22v10.407h3.22V7.28ZM3.968 2.094c-1.031 0-1.875.844-1.875 1.906 0 1.063.844 1.906 1.875 1.906C5 5.906 5.844 5.031 5.844 4c0-1.063-.844-1.906-1.875-1.906Z"
        id="linkedin"
      />
    </defs>
    <use fill="#B5BBCB" fillRule="nonzero" xlinkHref="#linkedin" />
  </svg>
)

export default SvgComponent
