import * as React from "react";

const Phone = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.923 3.026a.77.77 0 0 1 .67.39l.044.093 2.052 5.128a.77.77 0 0 1-.228.881l-.09.065-1.937 1.16.01.02a10.513 10.513 0 0 0 3.482 3.604l.33.198 1.161-1.935a.77.77 0 0 1 .84-.352l.106.033 5.128 2.052a.77.77 0 0 1 .476.61l.007.104v4.102c0 1.558-1.262 2.821-2.867 2.82C9.44 21.472 2.528 14.56 2 5.846a2.82 2.82 0 0 1 2.649-2.815l.172-.005h4.102Zm-.52 1.538H4.82c-.709 0-1.283.574-1.284 1.235.48 7.892 6.772 14.184 14.617 14.663.664 0 1.21-.505 1.275-1.151l.007-.132v-3.581l-4.037-1.615-1.2 2.003a.77.77 0 0 1-.901.334l-.1-.04a12.051 12.051 0 0 1-5.478-5.478.77.77 0 0 1 .206-.938l.088-.062L10.016 8.6 8.402 4.564Zm6.674 1.539a2.82 2.82 0 0 1 2.82 2.82.77.77 0 0 1-1.531.104l-.007-.104c0-.664-.505-1.21-1.151-1.275l-.131-.007a.77.77 0 0 1 0-1.538Zm0-4.103C18.9 2 22 5.1 22 8.923a.77.77 0 1 1-1.538 0 5.385 5.385 0 0 0-5.385-5.385.77.77 0 0 1 0-1.538Z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

export default Phone;
